const state = {
  currentUser: null,
  loggedIn: false,
};

const mutations = {
  SET_USER(state, user) {
    state.currentUser = user;
    state.loggedIn = !!user;
  },
  LOG_OUT(state) {
    state.currentUser = null;
    state.loggedIn = false;
  },
};

const actions = {
  async logIn({ commit }, userData) {
    commit("SET_USER", userData);
  },
  async logOut({ commit }) {
    commit("LOG_OUT");
  },
};

const getters = {
  loggedIn: (state) => state.loggedIn,
  currentUser: (state) => state.currentUser,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
