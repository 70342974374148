import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import store from "./state/store";

import BootstrapVueNext from "bootstrap-vue-next";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import { vMaska } from "maska";
import { VueQueryPlugin } from "@tanstack/vue-query";

import VueFeather from "vue-feather";

import "@/assets/scss/config/default/app.scss";
import "leaflet/dist/leaflet.css";
import "@/assets/scss/mermaid.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

// Impor CSS DataTables.net dan ekstensi Buttons
import "datatables.net-bs5/css/dataTables.bootstrap5.css"; // Default styling for DataTables
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.css"; // Styling for Buttons
import "datatables.net-bs5";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import jszip from "jszip";
import pdfmake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import DataTable from "datatables.net-vue3";
import DataTablesLib from "datatables.net-bs5";
DataTable.use(DataTablesLib);

// Make sure to set these globally
window.JSZip = jszip;
window.pdfMake = pdfmake;
window.pdfMake.vfs = vfsFonts.pdfMake.vfs;

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

createApp(App)
  .use(VueQueryPlugin)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVueNext)
  .component(VueFeather.type, VueFeather)
  .directive("maska", vMaska)
  .use(vClickOutside)
  .mount("#app");
